import { useEffect, useState } from "react";
import {navigate} from 'hookrouter';
import useIsMobile from "../hooks/useIsMobile";
import superagent from 'superagent';

export default function Register(){
    const isMobile = useIsMobile();
    const [username, setUserName] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [documento, setDocumento] = useState('');
    const [celular, setCelular] = useState('');
    const [email, setEmail] = useState('');
    const [pais, setPais] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [password, setPassword] = useState('');
    const [metodo, setMetodo] = useState('0');
    const [acepto, setAcepto] = useState(false);
    const [error, setError] = useState('');
    const permitir = true;
    function submit(){
        if (acepto && username !== '' && nombre !== '' && apellido !== '' && email !== '' && pais !== '' && ciudad !== '' && password !== '' && ( (metodo === '2') || (metodo === '1' && celular !== '' && documento !== '') )){
            const newUser = {
                username: username.trim(),
                nombre,
                apellido,
                documento,
                celular,
                email,
                pais,
                ciudad,
                metodo,
                password: password.trim()
            }
            //https://rominartfit.com:8443
            console.log(newUser);
            superagent.post('/db/challenge/register')
            .send(newUser)
            .end(function(err, res){
                if (res.ok) {
                    if (metodo === '1'){
                        superagent.post(`/mp/get?user=${username}`)
                        .send({n:nombre,a:apellido,e:email,d:documento})
                        .end((err,response)=>{
                            if (response.ok){
                                superagent.post(`/db/link?user=${username}`)
                                .send({link:response.body.url})
                                .end((err, res) => {
                                    if (res.ok){
                                        window.location.href = response.body.url;
                                    }
                                })
                            }
                        })
                    } else if (metodo === '2') {
                        superagent.post(`/db/link?user=${username}`)
                        .send({link:`https://rominartfit.com:8443/paypal?user=${username}`})
                        .end((err, res) => {
                            if (res.ok){
                                window.location.href = `https://rominartfit.com:8443/paypal?user=${username}`;
                            }
                        })
                    }
                }
                if (res.body.fail){
                    setError('Usuario ya utilizado, por favor utiliza otro nombre de usuario.');
                }
            });
        } else {
            setError('Por favor llene todos los campos.');
        }
    }
    
    function gotoLogin(){
        navigate('/login');
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    },[])
    return (
            <div className="w-full h-full flex flex-col items-center justify-start">
                {
                    !isMobile ? <img src="/assets/images/nuevo fondo.png" alt="" className="w-full"/> : null
                }

                <div className="w-full p-4 bg-challenge flex flex-col items-center justify-center text-2xl">
                <p className="md:w-1/2 text-center text-white">Accede a todo el desafío a tan solo</p>
                <span className="font-bold">$3100.-</span>
                <p className="md:w-1/2 text-center text-white">o si te encontras en el exterior a </p>
                <span className="font-bold">u$d35.-</span>
                <p className="md:w-1/2 text-center font-bold">POR ÚNICA VEZ</p>
                </div>
                <div className="w-full md:w-8/12 h-full bg-gris flex flex-col items-center justify-start py-10">
                    <p className="text-center font-bold">¿Ya tenés cuenta?</p>
                    <div onClick={gotoLogin} className="p-4 rounded-xl sombradura m-4 text-3xl bg-challenge text-black font-black cursor-pointer">
                        INGRESA
                    </div>
                    <p className="text-center font-bold">o</p>
                    <h1 className="text-3xl font-black mb-4">REGISTRARME</h1>
                    <p className="text-center font-bold">Si no tenés cuenta</p>
                    <p className="text-center font-bold mb-4">creala aquí:</p>
                    { !permitir ?
                        <>
                        <h1 className="text-3xl font-black mb-4">Proximamente...</h1>
                        </>
                    :
                        <>
                        <p className="text-center text-red-500 mb-2">{error}</p>
                        <input placeholder="Usuario" className="p-4 mb-4 w-8/12  md:w-4/12" type="text"  onChange={(e)=>setUserName(e.target.value)}/>
                        <input placeholder="Contraseña" className="p-4 mb-4 w-8/12  md:w-4/12" type="password"  onChange={(e)=>setPassword(e.target.value)}/>
                        <input placeholder="Nombre(s)" className="p-4 mb-4 w-8/12  md:w-4/12" type="text"  onChange={(e)=>setNombre(e.target.value)}/>
                        <input placeholder="Apellido(s)" className="p-4 mb-4 w-8/12  md:w-4/12" type="text"  onChange={(e)=>setApellido(e.target.value)}/>
                        <input placeholder="Email" className="p-4 mb-4 w-8/12  md:w-4/12" type="email"  onChange={(e)=>setEmail(e.target.value)}/>
                        <select onChange={(e)=>setMetodo(e.target.value)} className="p-4 bg-white mb-4 w-8/12  md:w-4/12">
                            <option>Método de pago</option>
                            <option value={1}>Argentina - Mercado Pago AR$3100.-único</option>
                            <option value={2}>Exterior - Paypal U$D35.-único</option>
                        </select>
                        {
                            metodo === "1" ?
                            <> 
                                <input placeholder="DNI" className="p-4 mb-4 w-8/12  md:w-4/12" type="text"  onChange={(e)=>setDocumento(e.target.value)}/>
                                <input placeholder="Celular" className="p-4 w-8/12  mb-4 md:w-4/12" type="text"  onChange={(e)=>setCelular(e.target.value)}/>
                            </>
                            :
                            null
                        }
                        <input placeholder="País" className="p-4 mb-4 w-8/12  md:w-4/12" type="text"  onChange={(e)=>setPais(e.target.value)}/>
                        <input placeholder="Ciudad" className="p-4 mb-4 w-8/12  md:w-4/12" type="text"  onChange={(e)=>setCiudad(e.target.value)}/>
                        <div className="w-8/12 md:w-4/12 flex items-center justify-between mb-4">
                            <p className="text-xs font-bold"><input onChange={(e)=>setAcepto(e.target.checked)} className="mr-2" type="checkbox"></input>Acepto bases y condiciones: Inscripción única de 1 (un) pago (ver valor según país de origen) para acceder al Desafío de 21 días. El usuario constata tener la condición física apta para tal actividad. RTFIT no se responsabiliza por ninguna dolencia consecuente de la mala realización de los ejercicios.<span className="italic text-gray-500">* La suscripción se dará de baja luego de los 3 meses de haber iniciado el curso.</span></p>
                        </div>
                        {
                            metodo === "1" ?
                            <>
                                <p className="bg-acent text-2xl font-bold italic p-4 mb-4 w-8/12  md:w-4/12">* Si mercado pago no te redirigió al finalizar el pago automáticamente, por favor presionar el botón de volver al sitio.</p>
                            </>
                            :
                            null
                        }
                        <button onClick={submit} className="transition duration-400 bg-challenge py-2 px-4 font-bold hover:bg-green-500 cursor-pointer">
                            REGISTRARME
                        </button>
                        </>
                    }
                </div>
            </div>
    );
}