import { useEffect, useState } from 'react';
import { useSesionContext } from '../context';
import { navigate } from 'hookrouter';
import VideoPlayer from './video';
import ItemClase from './itemClase';
import useIsMobile from '../../hooks/useIsMobile';

export default function Clases(){
   const isMobile = useIsMobile();
   const context = useSesionContext();
   const [video,setVideo] = useState(0);
   const videos = [
        {
            title: 'Tutorial + Clase de preparación',
            video: 'https://vimeo.com/541232203/da80d1b6be',
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia1',
            id: 1
         },
         {
            title: 'Brazos + ABS',
            video: 'https://vimeo.com/541236854/b1051cb486',
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia2',
            id: 2
         },
         {
            title: 'Piernas Fuerza + Velocidad',
            video: 'https://vimeo.com/541241166/a79be8372d',
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia3',
            id: 3
         },
         {
            title: 'Stretching',
            video: 'https://vimeo.com/541245375/9d81d6e64a',
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia4',
            id: 4
         },
         {
            title: 'Glúteos + Cardio',
            video: 'https://vimeo.com/541247851/dd6a7d4fe3',
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia5',
            id: 5
         },
         {
            title: 'Día libre',
            video: null,
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia6-LIBRE',
            id: null
         },
         {
            title: 'Fullbody',
            video: 'https://vimeo.com/541251939/455e9d833e',
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia6',
            id: 6
         },
         {
            title: 'Piernas + Movbox',
            video: 'https://vimeo.com/541255750/4b5d817085',
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia7',
            id: 7
         },
         {
            title: 'Glúteos + Planchas',
            video: 'https://vimeo.com/541259766/0615575120',
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia8',
            id: 8
         },
         {
            title: 'Stretching',
            video: 'https://vimeo.com/541264354/4cebab5b1a',
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia9',
            id: 9
         },
         {
            title: 'Trabajo en silla',
            video: 'https://vimeo.com/544023864/df144e304f',
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia10',
            id: 10
         },
         {
            title: 'Abdominales',
            video: 'https://vimeo.com/544029602/c832332fdd',
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia11',
            id: 11
         },
         {
            title: 'Día libre',
            video: null,
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia13',
            id: null
         },
         {
            title: 'Dance! + Deslizadores',
            video: 'https://vimeo.com/544035277/1c5bd90401',
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia12',
            id: 12
         },
         {
            title: 'Power cardio',
            video: 'https://vimeo.com/544041273/51f413791e',
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia13',
            id: 13
         },
         {
            title: 'Stretching',
            video: 'https://vimeo.com/544047358/0caaf40454',
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia14',
            id: 14
         },
         {
            title: 'Full piernas',
            video: 'https://vimeo.com/543981911/0029c433aa',
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia15',
            id: 15
         },
         {
            title: 'Planchas Xtreme',
            video: 'https://vimeo.com/545955590/307d79a705',
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia16',
            id: 16
         },
         {
            title: 'Fullbody',
            video: 'https://vimeo.com/545979244/69aadf577f',
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia17',
            id: 17
         },
         {
            title: 'Día libre',
            video: null,
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia20',
            id: null
         },
         {
            title: 'Cardio',
            video: 'https://vimeo.com/545939808/c8e4133f36',
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia18',
            id: 18
         },
         {
            title: 'Full ABS',
            video: 'https://vimeo.com/544006782/1a34b29c34',
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia19',
            id: 19
         },
         {
            title: 'Trabajo en silla PRO',
            video: 'https://vimeo.com/543992052/b877a5a57e',
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia20',
            id: 20
         },
         {
            title: 'Día final: Mix de ejercicios',
            video: 'https://vimeo.com/544018077/20e234f38f',
            view: false,
            imagen:'RTFIT-ChallengeConLau-MINIATURAS-dia21',
            id: 21
         }
   ];

   useEffect(() => {
      if (context.token === ''){
         navigate('/login');
      }
   },[context.token]);

    return (
       <div className="w-full h-full flex flex-col items-center justify-start">
          {isMobile?
            <>
               <img src="/assets/images/clases-mobile.png" className="w-screen" alt=""/>
               <img src="/assets/images/vos-mobile.png" className="w-screen" alt=""/>
            </>
          :
            <img src="/assets/images/logeado.png" className="w-screen" alt=""/>
          }
         <h1 className="font-bold text-2xl text-bold w-full text-center">Antes de empezar, no te olvides:</h1>
         <img src="/assets/images/barramedico.png" className="w-screen" alt=""/>
         <div className="w-screen h-full flex flex-col items-center justify-start">
               <p className='md:mt-10 text-lg'>Ahora si,</p>
               <h1 className="text-3xl font-black">¡A ENTRENAR!</h1>
               <div className="w-full flex flex-col md:flex-row items-start justify-center mt-10">

                  <div className="w-full md:w-4/12 flex flex-col items-center justify-start">
                     <div className="w-full h-full flex items-center justify-center">
                           <img src="/assets/images/clasesPorDia.png" alt="" className="mb-2"/>
                     </div>
                     <div className="lista">
                           {  context.token !== '' ?
                              videos.map((v,i) => <ItemClase key={i} i={i} video={v.video} setVideo={setVideo} title={v.title} actualVideo={video} dia={v.id} />)
                              :
                              null
                           }
                     </div>
                  </div>

                  <div className="w-full md:w-8/12 mt-2 md:mt-0 flex flex-col items-start justify-start">
                     <h1 className="font-black text-3xl">DÍA {videos[video].id ? videos[video].id : 'Libre'}</h1>
                     <VideoPlayer {...videos[video] } />
                  </div>

               </div>
         </div>
        </div>
    )
}