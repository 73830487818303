import {useEffect} from 'react';
import useIsMobile from '../hooks/useIsMobile';
import {navigate} from 'hookrouter';

export default function Home(){
    const isMobile = useIsMobile();

    function gotoRegister(){
        navigate('/registrarse');
    }
    
    useEffect(() => {
        window.scrollTo(0,0);
    })
    return (
        <>
        <div className="w-full h-full relative" id="inicio">
            <img className="w-full h-full object-contain z-10" src={`/assets/images/${isMobile ? 'hero-mobile.png' : 'hero-desktop.png'}`} alt="Romina y Laurita"/>
            {isMobile ? <div className="boton-invisible-2" onClick={gotoRegister}></div> : <div className="boton-invisible" onClick={gotoRegister}></div>}
        </div>
        {
            isMobile ? null : <div className="w-full h-4 bg-black"/>
        }
        <div className="w-full h-full p-10 flex flex-col md:flex-row items-center justify-center text-center">
            <div className="w-full md:w-1/3 h-full flex flex-col items-center justify-center p-5 md:p-20">
                <img src="/assets/images/carita 1.png" alt="ojos cerrados"/>
                <h2 className='font-bold'>Estamos acá</h2>
                <p className="font-light">para acompañarte, orientarte, ayudarte y enseñarte.</p>
            </div>
            <div className="w-full md:w-1/3 h-full flex flex-col items-center justify-center p-5 md:p-20">
                <img src="/assets/images/carita 2.png" alt="ojos cerrados y sonrisa"/>
                <p className="font-light">A qué puedas</p>
                <h2 className='font-bold'>incorporar a tus días </h2>
                <h2 className='font-bold'>el hábito de entrenar.</h2>
            </div>
            <div className="w-full md:w-1/3 h-full flex flex-col items-center justify-center p-5 md:p-20">
                <img src="/assets/images/carita 3.png" alt="ojos de estrella"/>
                <p className="font-light"><span className="font-bold">21 días</span> con nosotras para</p>
                <p className="font-light">trabajar todo el cuerpo,</p>
                <p className="font-light">reírte y enfocarte en vos.</p>
                <span className="text-xl font-bold">¡VAMOS!</span>
            </div>
        </div>
        <div className="w-full h-full p-10 bg-challenge flex flex-col md:flex-row items-center justify-between">
            <div className="w-full h-full flex flex-col items-center justify-center">
                <p className="w-full text-lg md:text-lg text-center">¿Estás lista para ser tu mejor versión?</p>
                <h2 className="font-black text-2xl md:text-3xl p-0">¡ESTE CHALLENGE</h2>
                <span className="font-black text-2xl md:text-3xl p-0 underline">ES PARA VOS!</span>
            </div>
            <div className="w-full h-full flex items-center justify-center mt-4 md:mt-0">
                <div onClick={gotoRegister} className="boton-empezar bg-white hover:bg-green-500 cursor-pointer  text-3xl">¡QUIERO EL CHALLENGE!</div>
            </div>
        </div>
        <div className="w-full h-full bg-white py-10 md:py-20">
            <div className="w-full h-1/3 flex flex-col md:flex-row items-center justify-center">
                <div className="w-9/12 md:w-full h-full flex items-center justify-center"><img src="/assets/images/21dias.png" alt="21 dias"/></div>
                <div className="w-9/12 md:w-full h-full flex items-center justify-center my-4 md:my-0"><img src="/assets/images/lista.png" alt="lo que te ofrece"/></div>
            </div>
            <div className="w-full flex items-center justify-center">
                <img className="w-full object-cover h-full" src={`/assets/images/${isMobile ? 'lyv-mobile.png' : '3fotos.png'}`} alt="Romina y Laurita"/>
            </div>
            <div className="w-full h-1/3 flex items-center justify-center flex-wrap bg-white">
                <div className="w-1/2 md:w-1/3 h-full flex items-center justify-center p-2 md:p-4"><img className="object-contain w-full md:w-9/12" src="/assets/images/modalidad.png" alt="ojos cerrados y sonrisa"/></div>
                <div className="w-1/2 md:w-1/3 h-full flex items-center justify-center p-2 md:p-4"><img className="object-contain w-full md:w-9/12" src="/assets/images/suscripcion.png" alt="ojos cerrados y sonrisa"/></div>
                <div className="w-1/2 md:w-1/3 h-full flex items-center justify-center p-2 md:p-4"><img className="object-contain w-full md:w-9/12" src="/assets/images/disponible.png" alt="ojos cerrados y sonrisa"/></div>
            </div>
        </div>
        {
            !isMobile ?
            <div className="w-full h-full relative">
            <div onClick={gotoRegister} className="boton-empezar mover">¡QUIERO EL CHALLENGE!</div>
            <img src="/assets/images/home 4.png" className="w-screen" alt=""/>
            </div>
            :
            <>
                <img src="/assets/images/desafio-mobile.png" className="w-screen" alt=""/>
                <img onClick={gotoRegister} src="/assets/images/home 4-mobile.png" className="w-screen" alt=""/>
            </>
        }
        <div className="w-full h-auto p-4 bg-black text-white text-center flex flex-col items-center justify-center text-2xl md:text-2xl">
            <div className="flex md:flex-row flex-col items-center justify-center">
                <div className="font-black">Una vez que accedas </div>
                <div className="font-black">con tu usuario,</div>
            </div> 
            <div className="flex md:flex-row flex-col items-center justify-center">
                <div className="text-challenge">vas a encontrar las 21 </div>
                <div className="text-challenge">clases del {isMobile? 'desafío.' : 'challenge.'}</div>
            </div>
        </div>
        {
            !isMobile ?
            <div className="w-full h-screen bg-white flex flex-col items-center justify-start">
            <div className="text-3xl mt-20">Cambiá tu vida.</div>
            <div className="text-3xl font-black">¡Queremos ayudarte a incorporar el hábito de entrenar!</div>
            <div className="w-full h-full flex items-center justify-center mt-10">
                <div className="w-1/3 h-full flex flex-col items-center justify-center text-center">
                    <h3 className="text-xl font-bold w-8/12">Romina Traeta</h3>
                    <p className="text-xl w-8/12">Instructora en musculación y creadora de la técnica RTFIT®</p>
                    <p className="text-xl mt-4 w-8/12">
                        Counselor, inquieta, siempre buscando innovar, creativa y con mucha energía! Mis especialidades en grupo y el autodesarrollo hicieron que la fusión cuerpo y mente sea en un éxito en la salud a través de la actividad física. Los Amo
                    </p>
                    <div className="flex items-center justify-center font-bold text-black text-3xl mt-4"><img src="/assets/images/logo-ig.png" className="mr-2" alt=""/> @rtfit.official</div>
                </div>
                <div className="w-1/3 h-full flex flex-col items-center justify-center text-center">
                    <img src="/assets/images/estrellasjuntas.png" className="mb-4 w-full" alt=""/>
                    <div onClick={gotoRegister} className="transition duration-400 boton-empezar w-7/12 bg-challenge hover:bg-green-500 cursor-pointer">
                        ¡QUIERO EL CHALLENGE!
                    </div>
                    <div className="text-xl mt-10">¿Tenés consultas? <span className="underline">Escribinos.</span></div>
                </div>
                <div className="w-1/3 h-full flex flex-col items-center justify-center text-center">
                    <h3 className="text-xl font-bold w-8/12">Laurita Fernández</h3>
                    <p className="text-xl w-8/12">Bailarina, conductora, actriz.</p>
                    <p className="text-xl mt-4 w-8/12">
                        Me encanta estar en movimiento porque se renueva la energía del cuerpo. Entrenar en equipo, como en este challenge, me ayuda a ser constante y disfrutar cada clase. Todo es mas lindo si logramos divertirnos! Me sume al desafío porque hace bien al cuerpo, física y espiritualmente.
                    </p>
                    <div className="flex items-center justify-center font-bold text-black text-3xl mt-4"><img src="/assets/images/logo-ig.png" className="mr-2" alt=""/> @holasoylaurita</div>
                </div>
            </div>
        </div>
        :
        <div className="w-full h-full p-4 flex flex-col items-center justify-start">
            <p className="w-10/12 mb-8 mt-12 font-black text-center flex items-center justify-center">¡Queremos ayudarte a incorporar el hábito de entrenar!</p>
            <div className="w-full h-full flex items-center justify-center">
                <img src="/assets/images/estrella-romi.png" className="w-2/5 mr-2" alt=""/>
                <div className="w-1/2 h-full flex flex-col items-start justify-start text-left text-xs">
                    <h3 className="font-bold">Romina Traeta</h3>
                    <p className="">Instructora en musculación y creadora de la técnica RTFIT®</p>
                    <p className="mt-2">
                        Counselor, inquieta, siempre buscando innovar, creativa y con mucha energía! Mis especialidades en grupo y el autodesarrollo hicieron que la fusión cuerpo y mente sea en un éxito en la salud a través de la actividad física. Los Amo
                    </p>
                    <div className="flex items-center justify-center font-bold text-black mt-4"><img src="/assets/images/logo-ig.png" className="mr-2" alt=""/> @rtfit.official</div>
                </div>
            </div>
            <div className="w-full h-full flex items-center justify-center mt-8">
                <img src="/assets/images/estrella-laurita.png" className="w-2/5 mr-2" alt=""/>
                <div className="w-1/2 h-full flex flex-col items-start justify-start text-left text-xs">
                    <h3 className="font-bold">Laurita Fernández</h3>
                    <p className="">Bailarina, conductora, actriz.</p>
                    <p className="mt-2">
                        Me encanta estar en movimiento porque se renueva la energía del cuerpo. Entrenar en equipo, como en este challenge, me ayuda a ser constante y disfrutar cada clase. Todo es mas lindo si logramos divertirnos! Me sume al desafío porque hace bien al cuerpo, física y espiritualmente.
                    </p>
                    <div className="flex items-center justify-center font-bold text-black mt-4"><img src="/assets/images/logo-ig.png" className="mr-2" alt=""/> @holasoylaurita</div>
                </div>
            </div>
            <div onClick={gotoRegister} className="transition duration-400 boton-empezar w-9/12 my-8 flex items-center justify-center bg-challenge hover:bg-green-500 cursor-pointer">
                ¡QUIERO EL CHALLENGE!
            </div>
        </div>
        }
        <div className="hero bg-gris text-center flex flex-col items-center justify-center" id="contacto">
            <h1 className="text-3xl font-black text-black">CONTACTO</h1>
            <div className="w-full flex  flex-col md:flex-row  items-center justify-center">
                    {
                        !isMobile?
                            <div className="w-full h-full mt-20 flex items-start justify-end mr-4">
                                <img src="assets/images/comotefue.png" className="h-3/5" alt=""/>
                            </div>
                            :
                            <div className="w-full text-center flex flex-col items-center justify-center m-4">
                                <p className="font-bold text-lg">¿Tenés consultas?</p>
                                <p>¡Escribinos!</p>
                            </div>
                    }
                <div className="w-full h-full flex flex-col items-center md:items-start justify-start md:mt-20">
                    <input type="text" placeholder="Tu nombre" className="w-8/12 md:w-6/12 p-4 mb-4"/>
                    <input type="text" placeholder="Tu email" className="w-8/12 md:w-6/12 p-4 mb-4"/>
                    <textarea placeholder="¿Cómo te fue?" className="w-8/12 md:w-6/12 p-4 mb-4"/>
                    <div className="w-6/12 flex items-center justify-center md:justify-start">
                        <div className="transition duration-400 bg-challenge py-2 px-4 font-bold hover:bg-green-500 cursor-pointer">
                            ENVIAR
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}