import { useEffect, useState } from "react";
import useIsMobile from "../hooks/useIsMobile";
import superagent from 'superagent';

export default function Gracias(props) {
    const { user } = props;
    const [estado, setEstado] = useState(0);
    const isMobile = useIsMobile();

    useEffect(() => {
        console.log('aca se va a acreditar el pago...');
        console.log(user);
        superagent.get(`/db/challenge/pago/mp?user=${user}`)
        .end(function(err, res){
            if (res.ok){
                setEstado(1);
                console.log(res.body.message);
                console.log('usuario pago');
            }
        });

    }, []);
    return (
        <div className="w-full h-full flex flex-col items-center justify-start">
            {
                !isMobile ? <img src="/assets/images/nuevo fondo.png" alt="" className="w-full"/> : null
            }
            <div className='h-40 w-full flex flex-col items-center justify-center'>
                GRACIAS, aguarde un segundo mientras acreditamos tu pago, en breve vas a poder ingresar al challenge!.
                {
                    estado === 1 ?
                    <div>
                        Tu pago fue registrado con exito, ya puedes disfrutar del challenge.
                    </div>
                    :
                    null
                }
            </div>
        </div>
    )
}