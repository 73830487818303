import {useState, useEffect} from 'react';

export default function useIsMobile(){
    const [mobile, setMobile] = useState(false);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        function resize() {
            setWidth(window.innerWidth);
        }
        resize();
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize)
    },[]);

    
    useEffect(() => {
        if (width < 800){
            setMobile(true);
        }else{
            setMobile(false);
        }
    },[width])
    
    return mobile;
}