import {navigate, usePath} from "hookrouter";
import useIsMobile from '../../hooks/useIsMobile';
import {useEffect, useState} from 'react';

export default function Header(){
    const routeActual = usePath();
    const isMobile = useIsMobile();
    const [openMenu, setOpenMenu] = useState(false);

    function goToContact(){
        if (isMobile){
            togleMenu();
        }
        if (routeActual === '/'){
            const max = document.documentElement.scrollHeight;
            const minus = window.innerHeight;
            window.scrollTo(0,max - (minus * 1.4));
        } else {
            navigate('/');
            const max = document.documentElement.scrollHeight;
            const minus = window.innerHeight;
            window.scrollTo(0,max - (minus * 1.4));
        }
    }
    function goToHome(){
        if (isMobile){
            togleMenu();
        }
        if (routeActual === '/'){
            window.scrollTo(0,0);
        } else {
            navigate('/');
            window.scrollTo(0,0);
        }
    }
    function goToRT(){
        window.location.href = 'https://rominartfit.com/login.html';
    }
    function goToRegister(){
        navigate('/registrarse');
    }

    function togleMenu(){
        setOpenMenu(!openMenu);
    }

    function renderMenuMobile(){
        let buttonListMobile = [
            {action:goToRegister,text:'QUIERO EL CHALLENGE',clases:'bg-challenge text-white'}
        ];
        if (routeActual === '/login' || routeActual === '/registrarse'){
            return null;
        }
        return buttonListMobile.map((button,i) => <div key={i} className={`option ${button.clases}`} onClick={()=>button.action()}>{button.text}</div>);
    }
    function renderRutinasButton(){
        if (routeActual === '/login' || routeActual === '/registrarse'){
            return null;
        }
        return (
            <>
                <li onClick={goToRegister} className={`transition duration-300 w-full h-full flex items-center justify-center text-center font-bold cursor-pointer bg-challenge text-black hover:text-white`}>¡QUIERO EL CHALLENGE!</li>
            </>
        )
    }

    return (
        <>
        <nav className={`${isMobile? 'fixed top-0 left-0 w-full h-16 z-50' : ''} header`}>
            <div className="w-1/2 md:w-3/12 h-full text-white font-bold text-3xl flex items-center justify-center">
                <img onClick={()=>navigate('/')} src="/assets/images/logo.png" alt="RTFIT"/>
            </div>
            <div className="w-1/2 md:w-9/12 h-full flex items-center justify-end">
                {isMobile ? renderMenuMobile() : 
                <>
                    <li onClick={goToHome} className={`option`}>HOME</li>
                    <li onClick={goToContact} className={`option`}>CONTACTO</li>
                    {renderRutinasButton()}
                </>
                }
            </div>
        </nav>
        {isMobile ? <div className="w-full h-20"></div> : null}
        <div className="flex items-center justify-around fixed bottom-5 right-0 w-1/2 md:w-1/3 h-20 z-50">
            <div className="flex flex-col items-center justify-center">
                <a href="https://api.whatsapp.com/send?phone=541168800010" className="transition duration-150 w-16 h-16 bg-acent rounded-full shadow-xl flex items-center justify-center hover:bg-white hover:text-acent cursor-pointer"><i className="ri-whatsapp-line text-3xl"></i></a>
                <p className="font-bold">CHALLENGE</p>
            </div>
            <div className="flex flex-col items-center justify-center">
                <a href="https://api.whatsapp.com/send?phone=541130130009" className="transition duration-150 w-16 h-16 bg-acent rounded-full shadow-xl flex items-center justify-center hover:bg-white hover:text-acent cursor-pointer"><i className="ri-whatsapp-line text-3xl"></i></a>
                <p className="font-bold">RTFIT</p>
            </div>
        </div>
        </>
    );
}