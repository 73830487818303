import { navigate } from "hookrouter";
import { useEffect, useState } from "react";
import { useSesionContext } from "../app/context";
import superagent from 'superagent';

export default function Login(){
    const context = useSesionContext();
    const [nick, setNick] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    function submit(e){
        e.preventDefault();
        superagent.post('/db/login')
        .send({username:nick,password})
        .set('accept', 'json')
        .end(async (err, res) => {
            if (err){
                setNick('');
                setPassword('');
                setError(err.message);
            }else if (res.body.user) {
                if (res.body.user.challenge){
                    context.setUser(res.body.user);
                    context.setToken(res.body.user._id);
                    navigate('/clases');
                } else {
                    console.log(res.body.user);
                    if (res.body.user.metodo !== '3'){
                        window.location.href = res.body.user.link;
                    } else {
                        context.setUser(res.body.user);
                        context.setToken(res.body.user._id);
                        navigate('/clases');
                    }
                }
            } else {
                setNick('');
                setPassword('');
                context.setToken('');
                setError(res.body.message);
            }
        });
    }

    useEffect(() => {
        if (context.user._id !== '' && context.user._id !== undefined ){
            navigate('/clases');
        }
    },[context.user]);

    useEffect(() => {
        window.scrollTo(0, 0);
    },[])
    return (
        <div className="w-full h-full flex flex-col items-center justify-start">
            <div className="w-full p-4 bg-challenge flex flex-col items-center justify-center text-2xl">
                <p className="md:w-1/2 text-center text-white">Nunca pares, nunca te conformes, estas comenzando a trabajar en una nueva versión de vos misma.</p>
                <span className="font-bold">¡Animate a más!</span>
            </div>
            <div className="w-full md:w-8/12 h-full bg-gris flex flex-col items-center justify-start py-10">
                    <h1 className="text-3xl font-black mb-4">INGRESAR</h1>
                    <p className="text-center font-bold mb-4">Ingresa con tus datos</p>
                <input type="text" className="p-4 mb-4 w-8/12 md:w-4/12" placeholder="Usuario" value={nick} onChange={(e) => setNick(e.target.value)}/>
                <input type="password" className="p-4 mb-4 w-8/12 md:w-4/12" placeholder="Contraseña" value={password} onChange={(e) => setPassword(e.target.value)}/>
                <p className="text-red-500">{error}</p>
                <div onClick={submit} className="transition duration-400 bg-challenge py-2 px-4 font-bold hover:bg-green-500 cursor-pointer mb-10">
                    INGRESAR
                </div>
            </div>
        </div>
    );
}