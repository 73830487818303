export default function Footer(){
    return (
        <footer className="w-full h-80 bg-gray-700 text-white flex flex-col items-center justify-end text-gray-700 text-3xl">
            <div className="w-full h-full flex items-center justify-center">
                <div className="w-1/3 md:w-1/6 h-full flex items-center justify-center">
                    <a href="https://es-la.facebook.com/RominaTraettaFIT/" className="transition duration-400 w-20 h-20 bg-white rounded-full flex items-center justify-center hover:scale-110 hover:shadow-xl hover:bg-challenge cursor-pointer">
                        <i className="ri-facebook-fill"></i>
                    </a>
                </div>
                <div className="w-1/3 md:w-1/6 h-full flex items-center justify-center">
                    <a href="https://www.instagram.com/rtfit.official/?hl=es-la" className="transition duration-400 w-20 h-20 bg-white rounded-full flex items-center justify-center hover:scale-110 hover:shadow-xl hover:bg-challenge cursor-pointer">
                        <i className="ri-instagram-line"></i>
                    </a>
                </div>
                <div className="w-1/3 md:w-1/6 h-full flex items-center justify-center">
                    <a href="mailto:romiylauchallenge@gmail.com" className="transition duration-400 w-20 h-20 bg-white rounded-full flex items-center justify-center hover:scale-110 hover:shadow-xl hover:bg-challenge cursor-pointer">
                        <i className="ri-mail-line"></i>
                    </a>
                </div>
            </div>
            <div className="bg-challenge w-full h-10 flex items-center justify-center text-xs md:text-lg">
                <p>Copyright 2020 © Diseño y Desarrollo por <a href="https://graphicmo.com.ar/" className="font-bold">graphicmo</a></p>
            </div>
        </footer>
    )
}