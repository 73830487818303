import {createContext, useState, useContext} from 'react';
import {navigator} from 'hookrouter';

const sessionContext = createContext({});

export function SesionProvider ({ children }) {
    const [user, setUser] = useState({});
    const [token, setToken] = useState('');

    async function login(username,password){
        if (username === 'romina' && password === 'rtfit'){
            await setToken('funcionando');
            await setUser({
                username,
                password,
                token,
                diasChallenge:[false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,]
            });
            return user;
        }

        return {message: 'Nombre o contraseña incorrectos.'};
    }

    async function logout(){
        setToken('');
        setUser({});
        navigator('/');
    }
    return (
        <sessionContext.Provider value={{user, token, setToken, setUser, login, logout}}>
            {children}
        </sessionContext.Provider>
    )
}

export function useSesionContext(){
    const consumer = useContext(sessionContext);

    return consumer;
}