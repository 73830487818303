import { useEffect, useState } from "react";
import { useSesionContext } from "../context";

export default function ItemClase({i, video, setVideo, title, actualVideo, dia}){
    const [state,setState] = useState('noDisponible');
    const context = useSesionContext();

    useEffect(()=>{
        if (i === actualVideo){
            setState('activo');
        } else if (i!== 0 && context.user.diasChallenge[i-1] === false) {
            setState('inactivo');
        } else {
            setState('disponible');
        }
    },[i, actualVideo,context.user.diasChallenge]);

    return (
        <li className={state} key={i} onClick={()=> setVideo(i)}>
            { !dia ? <span>{title}</span> : <><span>Día {dia}: </span>{title}</> }
        </li>
    );
}