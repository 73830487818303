import { useRoutes } from 'hookrouter';
import { SesionProvider } from './app/context';
import Clases from './app/clases/clases';
import Footer from './app/footer/footer';
import Header from './app/header/Header';
import Home from './home/home';
import Login from './login/Login';
import Register from './register/register';
import Gracias from './register/gracias';

function App() {
  const routes = {
    '/': () => <Home/>,
    '/login': () => <Login/>,
    '/clases': () => <Clases/>,
    '/registrarse': () => <Register/>,
    '/gracias/:user': ({user}) => <Gracias user={user}/>
  }
  const routerResult = useRoutes(routes);
  return (
    <main className="App">
      <SesionProvider>
        <Header/>
        {routerResult || <Home/>}
        <Footer/>
      </SesionProvider>
    </main>
  );
}

export default App;
