import { useEffect, useRef, useState } from "react";
import { useSesionContext } from "../context";
import ReactPlayer from 'react-player';
import superagent from 'superagent';

export default function VideoPlayer({title, video, view, imagen, id}){
    const context = useSesionContext();
    const [start, setStart] = useState(true);
    const [play,setPlay] = useState(false);
    const [canSee, setCanSee] = useState(false);
    const videoPlayer = useRef();

    function playVideo() {
        setPlay(!play);
        setStart(false);
    }

    function setRead(){
        let newUser = context.user;
        if (!newUser.diasChallenge[id-1]){
            superagent.post(`/db/challenge/see`)
            .send({username: newUser.username, dia: id - 1})
            .end((err,response)=>{
                if (response.ok){
                    newUser.diasChallenge[id-1] = true;
                    context.setUser(newUser);
                }
            })
        }
    }
    
    useEffect(() => {
        if (play){
            //videoPlayer.current.play();
            console.log(videoPlayer.current);
        }
    },[play])

    useEffect(()=>{
        const seeLater = (id!==1 && context.user.diasChallenge[id-2]) || false;
        const first = (id === 1 && context.token !== '');
        if ((!play && (video && seeLater)) || first) {
            setCanSee(true);
        } else {
            setCanSee(false);
        }
        if (play){
            setCanSee(false);
        }
    },[play, video, context.token, context.user.diasChallenge, id])

    useEffect(() => {
        setPlay(false);
        setStart(true);
    },[imagen])

    function progress(res){
        console.log(res.played);
        if (res.played > 0.5){
            setRead();
        }
    }
    return (
        <div className="relative w-full video pt-4">
            {canSee ?
            <div className="w-full h-full absolute top-0 left-0 cursor-pointer flex items-center justify-center">
                <div onClick={()=> playVideo()} className="transition duration-300 w-20 h-20 flex items-center justify-center rounded-full bg-challenge text-white hover:bg-transparent">
                    <i className="ri-play-line"/>
                </div>
            </div>            
            :
            null
            }
            {start ?
                <img src={`/assets/miniaturas/${imagen}.jpg`} className="w-full h-full object-contain" alt=""/>
                :
                <ReactPlayer ref={videoPlayer} autoplay onProgress={progress} width="100%" className="w-full" controls url={video} />
            }
            
        </div>
    );
}